<script>
import Gen from '@helper/Gen';
import BOGen from '@helper/BOGen';
import GlobalVue from '@helper/Global.vue';
import "@backend/css/pages/login-register-lock.css"

export default {
  extends: GlobalVue,
  data(){
    return {
      input: {},
    }
  },
  computed:{
    yearcopy(){
      return Gen.yearCopy(this.web.yearcopy)
    }
  },
  mounted(){
    this.refreshVariable()
  },
  methods:{
    submit(e){
      if(e.btnLoading()) return;
      BOGen.apirest("/login", this.input, (err, resp)=>{
        e.btnUnloading()
        if(err) return console.log(err);
        if(resp.error){
          return Gen.info(resp.message, 'danger')
        }
        
        Gen.putStorage("user", resp.user)
        Gen.putStorage("botk", resp.token)
        Gen.info(resp.message, 'success', 300).then(()=>{
          // location.reload()
          this.$router.push({name:"BoDashboard"})
        })
      }, 'POST')
    },
  }
}
</script>

<template>
  <section id="wrapper">
     <div class="login-register" :style="'background-image: url(\''+assets('bo_images','login-register.jpg')+'\');'">
            <div class="login-box card">
                <div class="card-body">
                    <VForm @resp="submit" class="form-horizontal form-material" id="loginform" action="index.html">
                        <h5 class="text-center m-b-10">Sign In</h5>
                        <div class="text-center m-b-20"><img :src="assets('bo_images','logo-icon.png')"  alt="homepage" class="dark-logo" /></div>

                        <div class="info"></div>
                        <div class="form-group ">
                            <div class="col-xs-12">
                                 <input class="form-control" type="text" v-model="input.username" name="username" required placeholder="username">
                            </div>
                        </div>
                        <div class="form-group m-b-30">
                            <div class="col-xs-12">
                                <input class="form-control" type="password" v-model="input.password" name="password" required placeholder="password">
                            </div>
                        </div>
                        <div class="form-group text-center">
                            <div class="col-xs-12 p-b-20">
                                <button class="btn btn-block btn-info btn-rounded btn-loading" type="submit">Log In</button>
                            </div>
                        </div>
                    </VForm>
                </div>
                <div class="col-sm-12 text-center copyright">
                  <p>
                    {{yearcopy}} &copy; Dimatique. All Rights Reserved.
                    <br/> Powered by <a target="_blank" href="http://lantai21.com/">Lantai21</a>
                  </p>
                </div>
            </div>
        </div>
  </section>
</template>